
import { defineComponent, onMounted, ref } from "vue";
import { useRoute, useRouter } from "vue-router";
import { ApiMerchant } from "@/core/api";
import moment from "moment";
import { useI18n } from "vue-i18n";
import { formatDate, setModuleBCN } from "@/core/directive/function/common";

export default defineComponent({
  name: "merchants-overview",
  components: {},
  setup() {
    const { t } = useI18n();
    const loading = ref<boolean>(true);
    const route = useRoute();
    const router = useRouter();

    const formData = ref({
      status: 10,
      name: "",
      short_key: "",
      last_validation_date: "",
      lifecycle_status: "",
      email: "",
      website: "",
      telephone: "",
      telephone2: "",
      fax: "",
      address: "",
      zip_code: "",
      city: "",
      country_iso_2: "",
      managing_directors: "",
      ut_commercial_registry: "",
      lei_register: "",
      vat_registration_number: "",
    });

    const getCompanyData = () => {
      ApiMerchant.getCompanyInfo({ id: route.params.id })
        .then(({ data }) => {
          loading.value = false;
          if (data.code == 0) {
            formData.value = data.data;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    };

    const backToList = () => {
      if (route.path.indexOf("offline-merchants") != -1) {
        router.push("/merchant-network/offline-merchants");
      } else {
        router.push("/merchants");
      }
    };

    onMounted(() => {
      setModuleBCN(t, route, router);
      getCompanyData();
    });

    return {
      t,
      formatDate,
      loading,
      formData,
      getCompanyData,
      backToList,
    };
  },
});
